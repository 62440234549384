import { Injectable } from '@angular/core';
import { CitadelService } from './citadel.service';
import { ElectronService } from './electron.service';
import { LogService } from './log.service';
import { PlatformService } from './platform.service';

@Injectable()
export class WifiService {

    constructor(
        private platform: PlatformService,
        private electron: ElectronService,
        private citadel: CitadelService,
        private logger: LogService
    ) { }

    private async executeCommand(command: string): Promise<string> {
        this.logger.info(`Executing command "${command}"`, this.citadel);

        return new Promise((resolve, reject) => {
            this.electron.childProcess.exec(command, (err, stdout, stderr) => {
                if (err) {
                    this.logger.error(`Got error from previous command - "${stderr}"`, this.citadel);
                    reject(stderr);
                    return;
                }
                this.logger.info(`Got output from previous command - "${stdout}"`, this.citadel);
                resolve(stdout);
            });
        });
    }

    private sanitizeNetworkManagerProperty(property: string): any {
        if (property === '--' || !property) {
            return undefined;
        }
        if (property === '*') {
            return true;
        }
        return property;
    }

    private async clearAllWifiConnections(): Promise<void> {
        await this.executeCommand(`nmcli --fields UUID,TIMESTAMP-REAL,TYPE con show | grep wifi |  awk '{print $1}' | while read line; do sudo nmcli con delete uuid $line; done`);
    }

    private async setWifiLocale(locale: string): Promise<boolean> {
        try {
            const output = await this.executeCommand(`sudo raspi-config nonint do_wifi_country ${locale}`);
            return output === 'OK';
        } catch (e) {
            this.logger.warn("Error occurred changing WiFi locale - likely not on Raspberry Pi platform.")
            return true;
        }
    }

    public async getMACAddresses() {
        return window.require('macaddress').all();
    }

    public async scan(): Promise<any[]> {
        const output = await this.executeCommand('sudo nmcli --mode multiline device wifi list --rescan yes');
        const rawLines = output.split('\n');
        const validProperties = ['in-use', 'security', 'mode', 'ssid', 'signal']
        const networks = [];
      
        let stagedProperties = {};
      
        for (let line of rawLines) {
            const splitRawNetworkProperty = line.split(':').map(s => s.trim());
        
            const networkPropertyName = splitRawNetworkProperty[0].toLowerCase();
            const networkPropertyValue = splitRawNetworkProperty[1];
        
            if (validProperties.indexOf(networkPropertyName) === -1) {
                continue;
            }
        
            if (stagedProperties.hasOwnProperty(networkPropertyName)) {
                networks.push(stagedProperties);
                stagedProperties = {};
            } else {
                stagedProperties[networkPropertyName] = this.sanitizeNetworkManagerProperty(networkPropertyValue)
            }
        }
      
        if (Object.keys(stagedProperties).length > 0) {
            networks.push(stagedProperties);
        }
      
        return networks.filter(network => network.ssid);
    }

    public async connect(ssid: string, password: string = undefined) {
        let command;

        if (password) {
            command = `sudo nmcli device wifi connect "${ssid}" password "${password}"`;
        } else {
            command = `sudo nmcli device wifi connect "${ssid}"`;
        }
      
        await this.clearAllWifiConnections();
        await this.setWifiLocale('US');

        const output = await this.executeCommand(command);
      
        const isSuccessful = output.indexOf('success') !== -1;

        if (!isSuccessful) {
            throw new Error(output);
        }

        return true;
    }
}
