import 'reflect-metadata';
import '../polyfills';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { SuiModule } from 'ng2-semantic-ui';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgMarqueeModule } from 'ng-marquee';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ElectronService } from './providers/electron.service';
import { WebviewDirective } from './directives/webview.directive';
import { AppComponent } from './app.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { PlayerComponent } from './components/tabs/player/player.component';
import { AdminComponent } from './components/tabs/admin/admin.component';
import { ConfigComponent } from './components/tabs/config/config.component';
import { PinComponent } from './components/tabs/pin/pin.component';
import { CitadelService } from './providers/citadel.service';
import { LoaderService } from './providers/loader.service';
import { CacheService } from './providers/cache.service';
import { UpdateService } from './providers/update.service';
import { LogService } from './providers/log.service';
import { PlatformService } from './providers/platform.service';
import { StoreService } from './providers/store.service';
import { WifiComponent } from './components/tabs/wifi/wifi.component';
import { WifiService } from './providers/wifi.service';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { RemoteService } from './providers/remote.service';
import { GlobalErrorHandler } from './error/error.handler';
import { ActionService } from './providers/action.service';
import { VPNService } from './providers/vpn.service';

@NgModule({
  declarations: [
    AppComponent,
    TabsComponent,
    PlayerComponent,
    PinComponent,
    AdminComponent,
    ConfigComponent,
    WifiComponent,
    WebviewDirective
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgMarqueeModule,
    SuiModule,
    SimpleNotificationsModule.forRoot()
  ],
  providers: [
    ElectronService,
    CitadelService,
    LoaderService,
    CacheService,
    UpdateService,
    LogService,
    StoreService,
    WifiService,
    RemoteService,
    ActionService,
    VPNService,
    PlatformService,
    AuthenticatedGuard,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
