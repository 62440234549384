import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CitadelService } from '../../../providers/citadel.service';
import { NotificationsService } from 'angular2-notifications';
import { LoaderService } from '../../../providers/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RemoteService } from '../../../providers/remote.service';
import { PlatformService } from '../../../providers/platform.service';
import { ElectronService } from '../../../providers/electron.service';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss']
})
export class PinComponent implements OnInit {

  public digits: any[] = ['', '', '', '', '', ''];
  public isPinNeeded: boolean = false;

  @Output()
  public authenticated: EventEmitter<any> = new EventEmitter<any>();

  private digitIndex = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notifications: NotificationsService,
    private loader: LoaderService,
    private citadel: CitadelService,
    private electron: ElectronService,
    public platform: PlatformService,
    public remote: RemoteService
  ) {

  }

  async ngOnInit() {
    this.loader.setLoading(true, 'Connecting to Citadel...');
    this.isPinNeeded = !await this.citadel.isAuthenticated();
    this.loader.setLoading(false);

    this.remote.setMapping("pin",
      [
        [{ action: () => this.addDigit(1) }, { action: () => this.addDigit(2) }, { action: () => this.addDigit(3) }],
        [{ action: () => this.addDigit(4) }, { action: () => this.addDigit(5) }, { action: () => this.addDigit(6) }],
        [{ action: () => this.addDigit(7) }, { action: () => this.addDigit(8) }, { action: () => this.addDigit(9) }],
        [{ action: () => this.back() }, { action: () => this.addDigit(0) }, { action: () => this.submit() }]
      ]
    );
    this.remote.setSelectedMapping("pin");

    if (!this.isPinNeeded &&  !this.route.snapshot.queryParams['pin']) {
      this.router.navigate(['/player'], { queryParamsHandling: "preserve" });
    } else {
      if (this.route.snapshot.queryParams['pin']) {
        this.preloadPin(this.route.snapshot.queryParams['pin']);
        return;
      }

      this.route.queryParams.subscribe(queryParams => {
        if (queryParams['pin']) {
          this.preloadPin(queryParams['pin']);
          return;
        }
      });
    }
  }

  private async preloadPin(pin: string) {
    if (pin) {
      pin = atob(decodeURIComponent(pin));
      if (pin.length === this.digits.length) {
        this.digits = pin.split('');
        this.citadel.savePin(pin);
        await this.submit();
      }
    }
  }

  public addDigit(n: number) {
    if (this.digitIndex < this.digits.length) {
      this.digits[this.digitIndex] = n;
      this.digitIndex++;
    }
    if (this.digitIndex === this.digits.length) {
      this.submit();
    }
  }

  public async submit() {
    const pin = this.digits.join('').trim();

    if (pin.length !== 6) {
      return false;
    }

    const { success, error } = await this.citadel.checkPin(pin);
    if (!success) {
      this.digits = ['', '', '', '', '', ''];
      this.digitIndex = 0;

      let errorMessage = (error && error.error ? error.error : (error.message ? error.message : 'Network error occurred - could not connect to Citadel servers'));
      this.notifications.info('Notification', errorMessage);
      this.citadel.savePin(null);
      return false;
    } else {
      // valid
      this.loader.setLoading(false)
      this.citadel.savePin(pin);
      this.isPinNeeded = false;
      this.authenticated.emit(true);

      await this.navigateToPlayer();

      return true;
    }
  }

  public async navigateToPlayer() {
    let config = await this.citadel.getConfig();

    if (config && config.autoFullscreenOnLaunch && this.platform.isWindows()) {
      const window = this.electron.remote.getCurrentWindow();
      window.maximize();
      window.setFullScreen(true);
    }
    if (config && config.autoChannelSelection) {
      this.router.navigate(['/player'], { queryParamsHandling: "merge", queryParams: { autoplay: config.autoChannelSelection._id } });
    } else {
      this.router.navigate(['/player'], { queryParamsHandling: "preserve" });
    }
    return true;
  }

  public back() {
    if (this.digitIndex !== 0) {
      this.digitIndex--;
    }
    this.digits[this.digitIndex] = '';
  }
}
