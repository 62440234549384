import { Component, OnInit } from '@angular/core';
import { CitadelService } from '../../../providers/citadel.service';
import { ElectronService } from '../../../providers/electron.service';
import IConfig from '../../../interfaces/IConfig';
import IChannel from '../../../interfaces/IChannel';
import { PlatformService } from '../../../providers/platform.service';
import { RemoteService } from '../../../providers/remote.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  public config: IConfig;
  public pin;

  public autoplayOptions: any[];

  constructor(
    private electron: ElectronService,
    private platform: PlatformService,
    private citadel: CitadelService,
    private remote: RemoteService
  ) { }

  async ngOnInit() {
    this.config = await this.citadel.getConfig();
    this.pin = this.citadel.getPin();
    this.autoplayOptions = await this.citadel.getChannels();
    this.autoplayOptions.unshift({ _id: null, name: 'Disable auto-play' });
    this.configureSelectedChannel();

    this.remote.setMapping("config",
      [
        [{ action: () => this.restartApplication(false) }, { action: () => this.restartApplication(true) }]
      ]
    );
    this.remote.setSelectedMapping("config");
  }

  private configureSelectedChannel() {
    if (!this.config) {
      return;
    }
    
    if (this.config.autoChannelSelection) {
      const foundChannel = this.autoplayOptions.find(option => option._id === this.config.autoChannelSelection._id);
      if (!foundChannel) {
        this.config.autoChannelSelection = this.autoplayOptions[0]; // null option
      } else {
        this.config.autoChannelSelection = foundChannel;
      }
    } else {
      this.config.autoChannelSelection = this.autoplayOptions[0]; // null option
    }
  }

  public onConfigChange() {

  }

  public restartApplication(resetPin: boolean = false) {
    if (resetPin) {
      this.citadel.savePin(null);
    }

    if (this.platform.isWindows()) {
      this.electron.remote.app.relaunch();
      this.electron.remote.app.exit(0);
    } else if (this.platform.isLinux()) {
      this.electron.ipcRenderer.send('reboot');
    } else {
      window.location.reload();
    }
  }
}
