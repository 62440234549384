import { Component, OnInit, HostListener } from "@angular/core";
import { CitadelService } from "../../../providers/citadel.service";
import IChannel from "../../../interfaces/IChannel";
import IFile from "../../../interfaces/IFile";
import { VgAPI } from "videogular2/core";
import { ElectronService } from "../../../providers/electron.service";
import IConfig from "../../../interfaces/IConfig";
import { NotificationsService } from "angular2-notifications";
import { LoaderService } from "../../../providers/loader.service";
import { CacheService } from "../../../providers/cache.service";
import { LogService } from "../../../providers/log.service";
import IBrandedPlayer from "../../../interfaces/IBrandedPlayer";
import { PlatformService } from "../../../providers/platform.service";
import { Buttons, RemoteService } from "../../../providers/remote.service";

@Component({
  selector: "app-player",
  templateUrl: "./player.component.html",
  styleUrls: ["./player.component.scss"],
})
export class PlayerComponent implements OnInit {
  public config: IConfig;

  public headlines: any[] = [];
  public forecast: any[] = [];

  public displayedChannels: IChannel[] = [];
  public activeChannel: IChannel = null;
  public hoveredChannel: IChannel = null;
  public hoveredPlayer: boolean = false;
  public lastHoveredTime: number = null;
  public lastBufferTime: Date = null;
  public lastVideoSeekTime: number = null;

  public isMuted: boolean = false;
  public resourceUrl: string = "";

  public currentPlayback = {
    currentIndex: 0,
    playback: [],
    currentItem: [],
    active: false,
  };

  public isOffline: boolean = false;
  public vgApi: VgAPI;
  public displayBox: boolean;

  private autoplay: boolean;

  constructor(
    private loader: LoaderService,
    private notifications: NotificationsService,
    private citadel: CitadelService,
    private electron: ElectronService,
    private log: LogService,
    public platform: PlatformService,
    public remote: RemoteService,
    private cache: CacheService
  ) { }

  async ngOnInit() {
    // dirty ugly hack for dirty ugly debugging
    window['__INTERNAL_PLAYER_REF'] = this;

    this.loader.setLoading(true);
    this.config = await this.citadel.getConfig();

    if (
      this.config &&
      this.config.autoFullscreenOnLaunch &&
      this.platform.isWindows()
    ) {
      const window = this.electron.remote.getCurrentWindow();
      window.maximize();
      window.setFullScreen(true);
    }

    this.setupPlaybackWatchdog();

    await this.updateChannels();

    this.activeChannel = this.displayedChannels.find(() => true);

    // setup polling requests
    this.citadel.setupPings();
    this.citadel.setupActionProcessing();
    this.citadel.setupConfigUpdates();
    this.citadel.setupScheduling((op, channelId) => {
      if (op === "play") {
        const channel = this.displayedChannels.find((c) => c._id === channelId);
        this.closeChannel();
        this.openChannel(channel);
      }
      if (op === "stop") {
        this.closeChannel();
      }
    });

    // setup key events
    document.addEventListener(
      "keyup",
      (e) => {
        switch (e.code) {
          case "Escape":
            if (this.currentPlayback.active) {
              this.closeChannel();
            }
            break;
        }
      },
      true
    );

    // setup remote control mappings
    this.remote.setMapping("player", [
      this.displayedChannels.map((channel) => {
        return {
          action: () => this.openChannel(channel),
          value: channel._id,
        };
      }),
    ]);

    this.remote.onButtonPress("player", Buttons.BACK, () => {
      this.closeChannel();
    });

    this.remote.setSelectedMapping("player");

    // setup player per specified configuration
    this.isMuted = this.config ? !this.config.playSound : false;

    if (this.config && this.config.autoChannelSelection) {
      const foundChannel = this.displayedChannels.find(
        (channel) => channel._id === this.config.autoChannelSelection._id
      );
      if (foundChannel) {
        this.autoplay = true;
        this.displayBox = true;
        this.openChannel(foundChannel);

        if (!this.platform.isBrowser()) {
          setTimeout(() => {
            this.forcePlay();
          }, 3000);
        }
      }
    }

    // setup connection events
    this.citadel.registerReconnectionCallback(() => {
      this.isOffline = false;
    });

    this.citadel.registerDisconnectCallback(() => {
      this.isOffline = true;
    });

    this.loader.setLoading(false);
  }

  @HostListener("document:mousemove", ["$event"])
  public onMouseMove(e) {
    this.hoveredPlayer = true;
    let valueOf = new Date().valueOf();
    this.lastHoveredTime = valueOf;

    setTimeout(() => {
      if (this.lastHoveredTime !== valueOf) {
        return;
      }
      this.hoveredPlayer = false;
    }, 2000);
  }

  public async setupPlaybackWatchdog() {
    this.validatePlayback();
    setInterval(() => {
      this.validatePlayback();
    }, 1000 * 5);
  }

  public forcePlay() {
    if (this.vgApi) {
      this.displayBox = false;
      this.vgApi.play();
    }
  }

  public async validatePlayback() {    
    this.log.info('[Watchdog] Validating playback...', this.citadel);

    if (!this.activeChannel) {
      return; // nothing to check
    }
    
    if (!this.vgApi) {
      this.log.error('[Watchdog] Player failed validation! No video API available!', this.citadel);
      return;
    }

    const status = this.vgApi.state;
    
    /*
    this.log.info('[Watchdog] Video player status: ' + status, this.citadel);
    this.log.info('[Watchdog] Last seek time: ' + this.lastVideoSeekTime, this.citadel);
    this.log.info('[Watchdog] Current seek time: ' + this.vgApi.currentTime, this.citadel);
    */

    if (status === 'playing' && this.lastVideoSeekTime !== null && this.lastVideoSeekTime === this.vgApi.currentTime) {
      this.log.warn('[Watchdog] Video is marked as playing but is not progressing - forcing playback', this.citadel);
      this.playNextVideo();
      return;
    }

    if (status !== 'playing') {
      this.log.warn('[Watchdog] Video player status was not playing - forcing playback', this.citadel);
      this.playNextVideo();
      return;
    }

    this.lastVideoSeekTime = this.vgApi.currentTime;
  }

  public roundForecast(n: number) {
    return Math.round(n);
  }

  public getCurrentTime() {
    const date = new Date();

    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  }

  public async mouseOverChannelElement(channel: IChannel) {
    this.activeChannel = channel;

    if (this.platform.isBrowser()) {
      return;
    }

    this.hoveredChannel = channel;
    this.openChannel(channel, true);
  }

  public async mouseOutChannelElement(channel: IChannel) {
    if (this.platform.isBrowser()) {
      return;
    }

    this.hoveredChannel = null;
  }

  public onPlayerReady(api: VgAPI) {
    this.log.info(`VG Player is ready!`);

    this.vgApi = api;

    this.vgApi
      .getDefaultMedia()
      .subscriptions.ended.subscribe(this.playNextVideo.bind(this));

    if (this.currentPlayback.active && this.displayBox && this.autoplay) {
      this.vgApi.pause();
    } else if (this.currentPlayback.active) {
      this.vgApi.play();
    }

    let videoElement = this.vgApi.videogularElement.children[0];

    videoElement.addEventListener("waiting", () => {
      this.lastBufferTime = new Date();
      this.log.info(`Buffering video player...`, this.citadel);
    });

    videoElement.addEventListener("playing", () => {
      if (this.lastBufferTime !== null) {
        let msBuffer = new Date().valueOf() - this.lastBufferTime.valueOf();
        this.log.info(
          `Playing video player (buffer took ${msBuffer}ms)...`,
          this.citadel
        );
      }
    });

    videoElement.addEventListener("ended", () => {
      this.lastBufferTime = new Date();
    });
  }

  public onPlayerError(error: any) {
    let video = error.target.parentNode;

    if (video.networkState === 3) {
      return;
    }

    this.log.error(
      `Error loading video - network state is (${video.networkState
      }) and error is "${JSON.stringify(video.error)}"`,
      this.citadel
    );

    this.playNextVideo();
  }

  private async playNextVideo() {
    if (this.hoveredChannel) {
      this.hoveredChannel = null;
      return;
    }

    if (this.resourceUrl) {
      URL.revokeObjectURL(this.resourceUrl);
    }

    this.log.info(`Playing next video - index ${this.currentPlayback.currentIndex}`, this.citadel);

    this.lastVideoSeekTime = null;

    let currentSlot =
      this.currentPlayback.playback[this.currentPlayback.currentIndex];

    if (!currentSlot) {
      return;
    }
    
    if (currentSlot.type === "ad") {
      // if the video is an ad
      this.citadel.sendProofOfPlay(this.currentPlayback.currentItem[0]);
    }

    if (this.isOffline) {
      this.log.info('Offline playback detected - pulling from cache...', this.citadel);
      const availableVideoEntries = this.cache.getCachedFiles();

      if (availableVideoEntries.length === 0) {
        this.log.error('Player is offline with no available cached content! Trying to fetch online content anyway...', this.citadel);
      } else {
        const randomIndex = Math.floor(Math.random() * availableVideoEntries.length);
        const randomCachedVideoEntry = availableVideoEntries[randomIndex];
  
        this.currentPlayback.currentItem = [{ _id: randomCachedVideoEntry.id }];
        this.resourceUrl = randomCachedVideoEntry.path;
        return;
      }
    }

    if (
      this.currentPlayback.currentIndex ===
      this.currentPlayback.playback.length - 1
    ) {
      this.log.info(`Reached end of playback - looping`, this.citadel);

      const currentActiveChannelId = this.activeChannel._id;
      await this.updateChannels();
      this.activeChannel = this.displayedChannels.find(
        (channel) => channel._id === currentActiveChannelId
      );
      this.currentPlayback.playback = this.activeChannel["__playback"];
      this.currentPlayback.currentIndex = -1;
    } else {
      this.currentPlayback.currentIndex++;
    }

    let attempts = 0;
    let slot;
    do {
      slot = await this.citadel.processSlot(
        this.currentPlayback.playback[this.currentPlayback.currentIndex],
        this.activeChannel._id
      );
      
      if (!slot) {
        this.cycleToNextVideo();
      }

      attempts++;
    } while (!slot && attempts < 100);

    if (slot) {
      this.currentPlayback.currentItem = [slot];

      this.resourceUrl = await this.getResource(this.currentPlayback.currentItem[0]);

      // not async
      this.reportPlay();
    } else {
      this.log.error('Failed to fetch slot after multiple attempts - trying again');
      this.playNextVideo();
    }

    if (this.vgApi.getDefaultMedia()) {
      this.vgApi.getDefaultMedia().currentTime = 0;
      this.vgApi.getDefaultMedia().play();
    } else {
      this.log.warn(
        `Could not play next video - no video element available?`,
        this.citadel
      );
    }
  }

  public cycleToNextVideo() {
    if (
      this.currentPlayback.currentIndex ===
      this.currentPlayback.playback.length - 1
    ) {
      this.currentPlayback.currentIndex = 0;
    } else {
      this.currentPlayback.currentIndex++;
    }
  }

  private async updateChannels() {
    try {
      this.displayedChannels = <IChannel[]>await this.citadel.getChannels();
    } catch (e) {
      this.log.error(`Failed to update channel - ${e.message}`, this.citadel);

      // this.notifications.error('Connection lost!', 'Player desynced with server!');
      this.displayedChannels = [...this.displayedChannels];
    }
  }

  private async reportPlay() {
    if (this.isOffline) {
      return false;
    }
  
    if (this.hoveredChannel) {
      return false;
    }

    const video = this.currentPlayback.currentItem[0];
    if (!video) {
      return false;
    }
    try {
      const isCached = await this.cache.hasCachedFile(video);
      await this.citadel.reportPlay(
        video["_id"],
        video["__channelId"],
        isCached,
        video["__playlistId"]
      );
      return true;
    } catch (e) {
      this.log.error(
        `Failed to report play of video (${video["_id"]}) - ${e.message}`,
        this.citadel
      );
      return false;
    }
  }

  public isBrandedChannel() {
    return this.config && this.config.customPlayer;
  }

  public async getResource(file: IFile) {
    if (!file) {
      return null;
    }
    const hasCachedFile = await this.cache.hasCachedFile(file);
    if (hasCachedFile) {
      const cachedFile = await this.cache.getCachedFile(file);
      return cachedFile.path;
    } else {
      await this.cache.writeCachedFile(file);
      return file.cdnUrl;
    }
  }

  public async openChannel(channel: IChannel, inline: boolean = false) {
    if (!channel) {
      return;
    }

    if (!inline) {
      this.hoveredChannel = null;
    }

    if (channel["__playback"] && channel["__playback"].length === 0) {
      this.notifications.error("Cannot play!", "No content in channel.");
      return;
    }

    let wasAlreadyPlaying = this.currentPlayback.active;

    this.activeChannel = channel;
    this.currentPlayback.playback = channel["__playback"];
    this.currentPlayback.currentIndex = 0;

    if (!wasAlreadyPlaying && !inline) this.loader.setLoading(true);

    let slot;
    do {
      slot = await this.citadel.processSlot(
        this.currentPlayback.playback[this.currentPlayback.currentIndex],
        this.activeChannel._id
      );
      if (!slot) {
        this.cycleToNextVideo();
      }
    } while (!slot);

    this.currentPlayback.currentItem = [slot];
    this.resourceUrl = await this.getResource(this.currentPlayback.currentItem[0]);

    this.log.info(`Got resource URL (${this.resourceUrl}) for latest playback item`);

    this.reportPlay();

    if (!inline) {
      this.currentPlayback.active = true;
    }

    if (this.vgApi) this.vgApi.play();

    if (!wasAlreadyPlaying && !inline) this.loader.setLoading(false);

    if (
      this.config &&
      this.config.autoFullscreenOnPlay &&
      !inline &&
      this.platform.isElectron()
    ) {
      const window = this.electron.remote.getCurrentWindow();
      if (window) {
        window.maximize();
        window.setFullScreen(true);
      }
    }
  }

  public closeChannel() {
    if (this.vgApi) {
      this.vgApi.pause();
    }

    this.currentPlayback.active = false;
    this.activeChannel = null;
  }
}
