import { TabsComponent } from './components/tabs/tabs.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PlayerComponent } from './components/tabs/player/player.component';
import { AdminComponent } from './components/tabs/admin/admin.component';
import { ConfigComponent } from './components/tabs/config/config.component';
import { WifiComponent } from './components/tabs/wifi/wifi.component';
import { PinComponent } from './components/tabs/pin/pin.component';
import { AuthenticatedGuard } from './guards/authenticated.guard';

const routes: Routes = [
    {
        path: '',
        component: TabsComponent,
        children: [
            { path: 'player', component: PlayerComponent, canActivate: [AuthenticatedGuard] },
            { path: 'admin', component: AdminComponent, canActivate: [AuthenticatedGuard] },
            { path: 'config', component: ConfigComponent, canActivate: [AuthenticatedGuard] },
            { path: 'wifi', component: WifiComponent },
            { path: 'pin', component: PinComponent },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
