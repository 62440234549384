import { Component, ViewEncapsulation, OnInit, ApplicationRef, HostListener, AfterViewInit } from '@angular/core';
import { ElectronService } from './providers/electron.service';
import { CitadelService } from './providers/citadel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from './providers/loader.service';
import { UpdateService } from './providers/update.service';
import { LogService } from './providers/log.service';
import { PlatformService } from './providers/platform.service';
import { RemoteService } from './providers/remote.service';

const projectPackageJSON = require('../../../package.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {

  public readyToRender: boolean = false;

  constructor(
    private citadel: CitadelService,
    private log: LogService,
    private update: UpdateService,
    private router: Router,
    private route: ActivatedRoute,
    public loader: LoaderService,
    public electron: ElectronService,
    public platform: PlatformService
  ) { }

  async ngOnInit() {
    this.log.info('Launching app...', this.citadel);
    this.update.checkForUpdates();
    this.loader.initializeOnPageload();
  }

  async ngAfterViewInit() {
    if (!this.platform.isLinux()) {
      this.initialize();
    } else {
      // give linux 30 (up from 15) seconds to try and get connected
      this.loader.setLoading(true, this.getBootMessage());
      setTimeout(() => {
        this.initialize();
      }, 60 * 1000);
    }
  }

  public getBootMessage() {
    const messages = [
      "Getting all your groovy content ready to go!",
      "Loading up for customer engagement!",
      "Almost there... get ready for awesome content!",
      "Countdown to creativity in place...",
      "It's almost go time!",
      "Powering up your waiting room..."
    ];

    return messages[
      Math.floor(Math.random() * messages.length)
    ];
  }

  private async initialize() {
    this.log.info('Initializing app...', this.citadel);
    // handle routing to PIN or player screen based on authentication
    let authenticated = await this.citadel.isAuthenticated();

    setTimeout(() => {
      let queryParams = this.route.snapshot.queryParams;

      if (!authenticated || queryParams['pin']) {
        this.router.navigate(['/pin'], { queryParamsHandling: "preserve" });
      } else {
        this.router.navigate(['/player'], { queryParamsHandling: "preserve" });
      }

      this.loader.setLoading(false);
      this.readyToRender = true;
    }, 1000);
  }

  public getVersion() {
    return projectPackageJSON.version;
  }

  @HostListener('window:keydown', ['$event'])
  public onKeyDown($event) {
    if ($event.key === 'S' && $event.shiftKey) {
      const url = this.router.url;
      if (url.indexOf('config') === -1) {
        this.router.navigate(['/config'], { queryParamsHandling: 'preserve' });
      } else {
        this.router.navigate(['/player'], { queryParamsHandling: 'preserve' });
      }
    }
  }

  public minimize() {
    if (!this.platform.isWindows()) {
      return;
    }
    const window = this.electron.remote.getCurrentWindow();
    window.minimize();
  }

  public isFillingScreen() {
    if (!this.platform.isWindows()) {
      return true;
    }
    const window = this.electron.remote.getCurrentWindow();
    return window.isMaximized() || window.isFullScreen();
  }

  public maximize() {
    if (!this.platform.isWindows()) {
      return;
    }
    const window = this.electron.remote.getCurrentWindow();
    if (window.isMaximized() || window.isFullScreen()) {
      window.setFullScreen(false);
      window.unmaximize();
    } else {
      window.maximize();
      window.setFullScreen(true);
    }
  }

  public close() {
    if (!this.platform.isWindows()) {
      return;
    }
    const window = this.electron.remote.getCurrentWindow();
    window.close();
  }
}