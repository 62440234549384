import { Injectable } from '@angular/core';
import { ElectronService } from './electron.service';
import { NotificationsService } from 'angular2-notifications';
import { PlatformService } from './platform.service';

@Injectable()
export class UpdateService {

    constructor(private electron: ElectronService, private notification: NotificationsService, private platform: PlatformService) {

    }

    public checkForUpdates() {
        if (!this.platform.isElectron()) {
            return;  
        }
        
        let processUpdateCheck;
        processUpdateCheck = () => {
            this.electron.ipcRenderer.removeListener('update::status', processUpdateCheck);
        };

        let processUpdateNotification;
        processUpdateNotification = () => {
            this.notification.info('Software update', 'A new update is downloading...')
            this.electron.ipcRenderer.removeListener('update::downloading', processUpdateNotification);
        };

        let processReadyNotification;
        processReadyNotification = () => {
            this.notification.info('Software update', 'Update ready! Restarting in 5 seconds...')
            this.electron.ipcRenderer.removeListener('update::ready', processReadyNotification);
        };

        let processNoneNotification;
        processNoneNotification = () => {
            this.notification.info('Software update', 'Currently running latest version of Citadel');
            this.electron.ipcRenderer.removeListener('update::none', processNoneNotification);
        }

        this.electron.ipcRenderer.on('update::downloading', processUpdateNotification);
        this.electron.ipcRenderer.on('update::ready', processReadyNotification);
        this.electron.ipcRenderer.on('update::status', processUpdateCheck);
        // this.electron.ipcRenderer.on('update::none', processNoneNotification);
        this.electron.ipcRenderer.send('update::check');
    }
}
