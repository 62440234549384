import { ErrorHandler, Injectable } from '@angular/core';
import IFile from '../interfaces/IFile';
import { ElectronService } from './electron.service';
import { LogService } from './log.service';
import { CitadelService } from './citadel.service';
import { PlatformService } from './platform.service';
import { Bellhop } from 'bellhop-iframe';
import { wrapBellhopFetch } from '../_utils/bellhop';
import { DomSanitizer } from '@angular/platform-browser';
import { base64ToUint8Array } from '../_utils/buffer';

@Injectable()
export class CacheService {
    private cache = [];
    private path;
    private parentPath;

    private bellhop: Bellhop;

    constructor(
        private sanitizer: DomSanitizer,
        private electron: ElectronService, 
        private platform: PlatformService, 
        private log: LogService, 
        private citadel: CitadelService
    ) {
        if (this.platform.isAndroid()) {
            this.bellhop = new Bellhop();
            this.bellhop.connect(undefined, 'https://localhost');
            return;
        }

        if (this.platform.isBrowser()) {
            return;
        }

        if (this.platform.isWindows()) {
            this.parentPath = this.electron.path.join(this.electron.remote.app.getPath('temp'), 'cdm-cache');
        } else {
            // linux
            this.parentPath = this.electron.path.join(this.electron.remote.app.getPath('cache'), 'cdm-cache');
        }
        
        this.path = this.electron.path.join(this.parentPath, 'citadel-cache-store.dat');
        this.log.info('Searching for cache file in "' + this.path + '"...', this.citadel);
        if (this.electron.fs.existsSync(this.path)) {
            this.loadCacheMetadata();
            this.log.info('Found cache and loaded ' + this.cache.length + ' entries!', this.citadel);
        } else {
            this.log.warn('Could not find logging file!', this.citadel);
        }
    }

    public async hasCachedFile(file: IFile): Promise<boolean> {
        if (this.platform.isAndroid()) {
            const androidCacheResponse = await wrapBellhopFetch<{ exists: boolean }>(this.bellhop, `cache::check`, file);
            return androidCacheResponse.exists;
        }

        if (this.platform.isBrowser()) {
            return false;
        }

        const entry = await this.getCachedFile(file);
        const path = (entry && entry.path) ? entry.path.replace('file://', '') : null;
        if (entry && !entry.limbo && !this.electron.fs.existsSync(path)) {
            this.invalidateCacheEntry(file);
            return false;
        }
        return entry && entry.ready;
    }

    public async getCachedFile(file: IFile): Promise<{ id: string, ready: boolean, path: any, limbo: boolean }> {
        if (!file) {
            return null;
        }

        if (this.platform.isAndroid()) {
            const cachedResult = await wrapBellhopFetch<{ data: string }>(this.bellhop, 'cache::get', file);

            const safeResourceUrl = this.sanitizer.bypassSecurityTrustUrl(cachedResult.data);
            
            return {
                id: file._id,
                ready: true,
                path: safeResourceUrl,
                limbo: false
            };
        }

        if (this.platform.isBrowser()) {
            return null;
        }
        let entry = this.cache.find(c => c.id === file._id);
        if (entry) {
            return { ...entry };
        } else {
            return null;
        }
    }

    public getCachedFiles(): any[] {
        if (!this.cache || this.platform.isBrowser()) {
            return null;
        }
        
        return this.cache.filter(entry => entry.ready);
    }

    public getCacheVersion() {
        if (this.platform.isBrowser()) {
            return null;
        }

        const cacheVersionPath = this.electron.path.join(this.parentPath, 'version');
        return this.electron.fs.readFileSync(cacheVersionPath);
    }

    public writeCacheVersion() {

    }

    public invalidateCacheEntry(file: IFile) {
        if (this.platform.isBrowser()) {
            return;
        }

        this.cache = this.cache.filter(entry => entry.id !== file._id);
        this.dumpCacheMetadata();
    }

    public loadCacheMetadata() {
        if (this.platform.isBrowser()) {
            return;
        }

        let cacheInfo = this.electron.fs.readFileSync(this.path).toString();
        try {
            this.cache = JSON.parse(cacheInfo);
        } catch (e) {
            this.log.error(`An error occurred trying to load cache - ${e}`, this.citadel);
            this.electron.fs.unlinkSync(this.path);
        }
    }

    public dumpCacheMetadata() {
        if (!this.electron.fs.existsSync(this.parentPath)) {
            this.electron.fs.mkdirSync(this.parentPath);
        }
        this.electron.fs.writeFileSync(this.path, JSON.stringify(this.cache));
    }

    public async writeCachedFile(file: IFile) {
        if (!file) {
            return false;
        }

        if (this.platform.isAndroid()) {
            const writeResult = await wrapBellhopFetch<{ success: boolean }>(this.bellhop, 'cache::write', file);
            return writeResult.success;
        }

        if (this.platform.isBrowser()) {
            return false;
        }

        let entry = this.cache.find(c => c.id === file._id);
        if (entry) {
            return;
        } else {
            entry = {
                id: file._id,
                ready: false,
                path: null,
                limbo: true
            };
            this.cache.push(entry);
        }

        let processCompletedCacheWrite;
        processCompletedCacheWrite = (event, path) => {
            this.log.info('Successfully downloaded cached file (' + entry.id + ')', this.citadel);
            const foundEntry = this.cache.find(c => c.id === file._id);
            foundEntry.ready = true;
            foundEntry.path = `file://${path}`;
            foundEntry.limbo = false;

            this.dumpCacheMetadata();

            this.electron.ipcRenderer.removeListener('cache::write::complete::' + file._id, processCompletedCacheWrite, true);
        };

        this.electron.ipcRenderer.on('cache::write::complete::' + file._id, processCompletedCacheWrite);
        this.electron.ipcRenderer.send('cache::write', file, this.electron.remote.app.getAppPath());
    }
}
