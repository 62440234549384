import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfig } from '../../environments/environment';
import { ElectronService } from './electron.service';
import IConfig from '../interfaces/IConfig';
import { LogService } from './log.service';
import { PlatformService } from './platform.service';
import { StoreService } from './store.service';
import { ActionService } from './action.service';
import { VPNService } from './vpn.service';

const projectPackageJSON = require('../../../../package.json');

const API_URL = AppConfig.api.url;

const PING_INTERVAL = 1000 * 30;
const CONFIG_UPDATE_INTERVAL = 1000 * 60;
const ACTION_PROCESS_INTERVAL = 1000 * 60;
const SCHEDULE_CHECK_INTERVAL = 1000 * 30;

@Injectable()
export class CitadelService {

    private disconnectEmitter: EventEmitter<any> = new EventEmitter();
    private reconnectionEmitter: EventEmitter<any> = new EventEmitter();
    private authenticated: EventEmitter<any> = new EventEmitter();

    private scheduleCallbacks: any[] = [];
    private cachedConfig: IConfig;

    private stoppedBecauseNetworkIssues: boolean = false;

    private isPingRunning: boolean = false;
    private isConfigUpdateRunning: boolean = false;
    private isActionsRunning: boolean = false;
    private isSchedulingRunning: boolean = false;
    private isOnFirstSchedulingPass: boolean = true;

    constructor(
        private http: HttpClient,
        private platform: PlatformService,
        private log: LogService,
        private store: StoreService,
        private electron: ElectronService,
        private action: ActionService,
        private vpn: VPNService
    ) {
    }

    private async ping() {
        const pin = this.getPin();
        if (!pin) {
            return false;
        }
        this.log.info(`Pinging URL using "${API_URL}/ping" with status`, this);

        try {
            const isAndroid = this.platform.isAndroid();
            await this.http.post(
                `${API_URL}/ping`,
                {
                    pin: pin,
                    vpnip: this.vpn.getVPNIP(),
                    modeldisplayname: isAndroid ? 'Android' : 'Desktop App',
                    friendlyname: (this.platform.isElectron() ? this.electron.os.hostname() : null)
                },
                { responseType: 'text', headers: { 'client-version': this.getVersionString(), 'x-client-identifier': pin } }
            ).toPromise();

            if (this.stoppedBecauseNetworkIssues) {
                this.log.info('Reconnected to services!', this);
                this.stoppedBecauseNetworkIssues = false;
                this.reconnectionEmitter.next('reconnect');
            }
        } catch (e) {
            this.log.error(`Failed to ping - ${e.message}`, this);
            this.stoppedBecauseNetworkIssues = true;
            this.disconnectEmitter.next('disconnect');
            // this.notifications.error('Connection lost!', 'Player desynced with server - will automatically reconnect when Internet is reconnected');
        }
    }

    public getAuthenticationEventEmitter() {
        return this.authenticated;
    }

    public getVersionString() {
        if (this.platform.isAndroid()) {
            return `ANDROID ${projectPackageJSON.version}`;
        } else if (this.platform.isBrowser()) {
            return `BROWSER ${projectPackageJSON.version}`;
        } else if (this.platform.isWindows()) {
            return `WIN32 ${projectPackageJSON.version}`;
        } else if (this.platform.isLinux()) {
            return `LINUX ${projectPackageJSON.version}`;
        } else {
            return `DESKTOP ${projectPackageJSON.version}`;
        }
    }

    public async registerDisconnectCallback(callback: any) {
        if (callback) {
            this.disconnectEmitter.subscribe(() => {
                callback();
            });
        }
    }

    public async registerReconnectionCallback(callback: any) {
        if (callback) {
            this.reconnectionEmitter.subscribe(() => {
                callback();
            });
        }
    }

    public async reportPlay(videoId: string, channelId: string, isCached: boolean, playlistId: string = null) {
        const pin = this.getPin();
        this.log.info(`Reporting play of video (${videoId}) using "${API_URL}/play"`, this);

        const response = await this.http.post(
            `${API_URL}/play`,
            {
                pin: pin,
                videoid: videoId,
                channelid: channelId,
                playlistid: playlistId,
                cached: isCached
            },
            { responseType: 'text', headers: { 'x-client-identifier': pin } }
        ).toPromise();
        return response;
    }

    public async reportLog(level: string, message: string, pin: string = null) {
        if (!pin) {
            pin = this.getPin();
        }

        try {
            const headers = pin ? { 'x-client-identifier': pin } : {};

            const response = await this.http.post(
                `${API_URL}/log`,
                {
                    pin: pin,
                    level: level,
                    message: message
                },
                { responseType: 'text', headers: headers }
            ).toPromise();
            return response;
        } catch (e) {
            // ignore error, don't want to log an error here because it'll recursively loop
        }
    }

    public async getSystemUUID() {
        if (this.platform.isElectron()) {
            return await (window.require('node-machine-id').machineId());
        } else {
            return '';
        }
    }

    public async checkPin(pin: string) {
        const systemUUID = await this.getSystemUUID();

        try {
            this.log.info(`Checking PIN (${pin}) with system UUID "${systemUUID}" using ${API_URL}/content/${pin}/${systemUUID}`, this);

            await this.http.get(
                `${API_URL}/content/${pin}/${systemUUID}`,
                { headers: { 'x-client-identifier': pin } }
            ).toPromise();

            this.log.info(`PIN (${pin}) was valid`, this);
            this.authenticated.next(true);

            return { success: true }
        } catch (e) {
            this.log.error(`PIN was NOT valid (${JSON.stringify(e)})`, this);

            return { success: false, error: e.error }
        }
    }

    public async processActions() {
        const actions = await this.getActions();
        if (!actions) {
            return;
        }

        const shellAvailability = {
            'powershell': this.platform.isWindows(),
            'batch': this.platform.isWindows(),
            'bash': this.platform.isLinux()
        }

        for (let action of actions) {
            let isShellAvailable = shellAvailability[action.type];
            if (!isShellAvailable) {
                this.log.error(`Could not execute action (${action._id}) using shell (${action.type}) because it was not available on this device`, this);
                await this.completeAction(action._id, 'error', 'Cannot execute - specified shell not available on device');
                continue;
            }

            this.log.info(`Executing action (${action._id})...`, this);

            try {
                const executionResult: any = await this.action.executeAction(action._id, action.type, action.script);
                this.log.info(`Completed execution of action (${action._id}) with status ${executionResult.status}`, this);

                this.completeAction(action._id, executionResult.status, executionResult.output);
            } catch (e) {
                this.log.error(`Failed to execute action (${action._id})`, this);
                this.completeAction(action._id, 'error', e.message);
            }
        }
    }

    public async getActions() {
        const pin = this.getPin();

        const systemUUID = await this.getSystemUUID();

        try {
            this.log.info(`Getting queued actions for (${pin}) with system UUID "${systemUUID}" using ${API_URL}/actions/${pin}/${systemUUID}`, this);

            let actions = await this.http.get(
                `${API_URL}/actions/${pin}`,
                { headers: { 'x-client-identifier': pin } }
            ).toPromise();

            return actions['actions'];
        } catch (e) {
            this.log.error(`Failed to fetch actions (${e.message})`, this);

            return null;
        }
    }

    public async completeAction(actionId: string, status: string, output: string) {
        const pin = this.getPin();

        const systemUUID = await this.getSystemUUID();

        try {
            this.log.info(`Marking action as complete for (${pin}) with system UUID "${systemUUID}" using ${API_URL}/actions/${pin}/${systemUUID}`, this);

            let actions = await this.http.post(
                `${API_URL}/actions/${pin}`,
                {
                    actionId: actionId,
                    status: status,
                    output: output
                },
                { headers: { 'x-client-identifier': pin } }
            ).toPromise();

            return actions['actions'];
        } catch (e) {
            this.log.error(`Failed to fetch actions (${e.message})`, this);

            return null;
        }
    }

    public async isAuthenticated() {
        const pin = this.getPin();
        if (!pin) {
            return false;
        }
        return (await this.checkPin(<string>pin)).success;
    }

    public async getChannels() {
        const pin = this.getPin();
        const systemUUID = await this.getSystemUUID();
        const offset = new Date().getTimezoneOffset();

        if (!pin) {
            return null;
        }

        this.log.info(`Requesting content metadata using ${API_URL}/content/${pin}/${systemUUID}`, this);

        const request = await this.http.get(
            `${API_URL}/content/${pin}/${systemUUID}?offset=${offset}`,
            { headers: { 'x-client-identifier': pin, 'timeout': `${5 * 1000}` } }
        ).toPromise();

        this.log.info(`Got ${request['channels'].length} channels from request`, this);

        return request['channels'];
    }

    public async setupPings() {
        if (this.isPingRunning) {
            return;
        }
        this.isPingRunning = true;

        setInterval(() => this.ping(), PING_INTERVAL);
        this.ping();
    }

    public async setupConfigUpdates() {
        if (this.isConfigUpdateRunning) {
            return;
        }
        this.isConfigUpdateRunning = true;

        setInterval(() => this.updateConfig(), CONFIG_UPDATE_INTERVAL);
        this.updateConfig();
    }

    public async setupScheduling(opCallback: any) {
        this.scheduleCallbacks.push(opCallback);
        if (this.isSchedulingRunning) {
            return;
        }
        this.isSchedulingRunning = true;

        setInterval(() => this.checkScheduling(), SCHEDULE_CHECK_INTERVAL);
        this.checkScheduling();
    }

    public async setupActionProcessing() {
        if (this.isActionsRunning) {
            return;
        }
        this.isActionsRunning = true;

        setInterval(() => this.processActions(), ACTION_PROCESS_INTERVAL);
        this.processActions()
    }

    // storage

    public async getConfig(): Promise<IConfig> {
        if (this.cachedConfig) {
            return this.cachedConfig;
        } else {
            return this.updateConfig();
        }
    }

    public async checkScheduling(): Promise<void> {
        const config = await this.getConfig();

        if (!config) {
            this.log.warn(`Could not fetch config - skipping this scheduling check.`, this);
            return;
        }

        if (!config.scheduling) {
            // No scheduling configured - ignore
            return;
        }

        function processHourString(str) {
            if (!str) {
                return null;
            }
            str = str.toLowerCase();
            const match = str.match(/\d|:/g);
            if (!match) {
                return null;
            }
            const time = match.join('');
            let ret;
            if (time.indexOf(':')) {
                let split = time.split(':').map(s => parseInt(s));
                ret = { hour: split[0], minutes: split[1] };
            } else {
                ret = { hour: parseInt(time), minutes: 0 }
            }

            if (str.indexOf('pm') !== -1 && ret.hour !== 12) {
                ret.hour += 12;
            }

            return ret;
        }

        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const scheduling = JSON.parse(config.scheduling);

        if (!scheduling.enableScheduling) {
            return;
        }

        const offset = new Date().getTimezoneOffset();
        const day = days[new Date().getDay()];
        const times = {
            start: null,
            end: null
        };

        if (scheduling.enableDayToDayScheduling) {
            const scheduledForToday = scheduling['schedulingEnabledFor' + day];
            if (!scheduledForToday) {
                return;
            }
            times.start = scheduling['schedulingStartFor' + day];
            times.end = scheduling['schedulingEndFor' + day];
        } else {
            times.start = scheduling.scheduledStart;
            times.end = scheduling.scheduledEnd;
        }

        if (!times.start || !times.end) {
            return;
        }

        times.start = processHourString(times.start);
        times.end = processHourString(times.end);


        if (!times.start || !times.end) {
            return;
        }

        const offsetTime = ((new Date().getUTCHours() * 60) + new Date().getMinutes()) - offset;

        const startTime = (times.start.hour * 60) + times.start.minutes;
        const endTime = (times.end.hour * 60) + times.end.minutes;

        const startOffset = Math.abs(startTime - offsetTime);
        const endOffset = Math.abs(endTime - offsetTime);

        if (this.isOnFirstSchedulingPass && offsetTime > startTime && offsetTime < endTime) {
            for (const callback of this.scheduleCallbacks) {
                this.log.info('Initiating scheduled playback', this);
                callback('play', scheduling.channel['_id']);
            }
        }
        else if (startOffset <= 1) {
            for (const callback of this.scheduleCallbacks) {
                this.log.info('Initiating scheduled playback', this);
                callback('play', scheduling.channel['_id']);
            }
        }
        else if (endOffset <= 1) {
            for (const callback of this.scheduleCallbacks) {
                this.log.info('Initiating scheduled stop', this);
                callback('stop', scheduling.channel['_id']);
            }
        }

        this.isOnFirstSchedulingPass = false;
    }

    public async updateConfig(): Promise<IConfig> {
        const pin = this.getPin();
        if (!pin) {
            return null;
        }
        try {
            this.log.info(`Requesting config metadata using "${API_URL}/config/${pin}"`, this);

            const config = await this.http.get(
                `${API_URL}/config/${pin}`,
                { headers: { 'x-client-identifier': pin } }
            ).toPromise();
            this.cachedConfig = <IConfig>config;
            if (this.electron && this.electron.ipcRenderer) {
                this.electron.ipcRenderer.send('autolaunch::change', this.cachedConfig.autoLaunch, true);
            }

            return this.cachedConfig;
        } catch (e) {
            this.log.error(`Error when requesting config metadata - ${e.message}`, this);
        }

    }

    public async processSlot(slot, channelId) {
        if (!slot) {
            return null;
        }

        if (!slot.metadata) {
            return slot;
        }

        if (slot.metadata === 'preloaded') {
            return { ...slot.resource };
        } else if (slot.metadata === 'fetch') {
            try {
                const fetched = await this.http.get(
                    `${slot.remote}`,
                    { headers: { 'x-client-identifier': this.getPin() } }
                ).toPromise();

                if (!fetched) {
                    return null;
                }

                fetched['__channelId'] = channelId;

                return { ...fetched };
            } catch (e) {
                this.log.error(`Error occurred fetching dynamic resource - ${e.message}`, this);
                return null;
            }
        }
    }

    public async sendProofOfPlay(video) {
        if (!video) {
            return;
        }
        const url = video['__proof_of_play'];
        try {
            await this.http.get(url).toPromise();
        } catch (e) {
            this.log.error(`Error in response to proof of play request (${e.message})`, this);
        }
        return;
    }

    public savePin(pin: string) {
        this.store.set('pin.value', pin);
    }

    public getPin(): string {
        return this.store.get('pin.value');
    }
}
