import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CitadelService } from '../../providers/citadel.service';
import { ElectronService } from '../../providers/electron.service';
import { PlatformService } from '../../providers/platform.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TabsComponent implements OnInit {

  public isAuthenticated: boolean = false;

  public options: any = {
    position: ["top", "center"],
    timeOut: 5000,
    lastOnBottom: true,
    animate: "fade"
  };

  constructor(private citadel: CitadelService, private electron: ElectronService, public platform: PlatformService) { }

  async ngOnInit() {
    this.isAuthenticated = await this.citadel.isAuthenticated();

    this.citadel.getAuthenticationEventEmitter().subscribe(() => this.onAuthenticate());
  }

  public isFillingScreen() {
    if (!this.platform.isWindows()) {
      return true;
    }
    const window = this.electron.remote.getCurrentWindow();
    return window.isMaximized() || window.isFullScreen();
  }

  public shutdown() {
    this.electron.ipcRenderer.send('shutdown');
  }

  public reboot() {
    this.electron.ipcRenderer.send('reboot');
  }

  public onAuthenticate() {
    this.isAuthenticated = true;
  }
}
