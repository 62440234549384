import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CitadelService } from '../providers/citadel.service';
import { LoaderService } from '../providers/loader.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {
    constructor(
        private router: Router,
        private citadel: CitadelService,
        private loader: LoaderService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        this.loader.setLoading(true);
        let authenticated = await this.citadel.isAuthenticated();
        this.loader.setLoading(false);
        if (!authenticated || route.queryParams.pin) {
            this.router.navigate(['/pin'], { queryParamsHandling: 'preserve' });
            return false;
        } else {
            return true;
        }
    }
}
