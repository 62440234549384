import { Injectable } from '@angular/core';
import { ElectronService } from './electron.service';
import { CitadelService } from './citadel.service';
import { PlatformService } from './platform.service';

@Injectable()
export class LogService {

    constructor(private electron: ElectronService, private platform: PlatformService) {

    }

    public info(message: string, citadel: CitadelService = null, pin: string = null) {
        console.log(`[info] - ${message}`);

        if (citadel) {
            citadel.reportLog('info', message, pin)
        }
        
        if (this.platform.isElectron()) {
            this.electron.ipcRenderer.send('log::info', `${message}`);
        }
    }

    public warn(message: string, citadel: CitadelService = null, pin: string = null) {
        console.warn(`[warn] - ${message}`);

        if (citadel) {
            citadel.reportLog('warn', message, pin);
        }

        if (this.platform.isElectron()) {
            this.electron.ipcRenderer.send('log::warn', `${message}`);
        }
    }

    public error(message: string, citadel: CitadelService = null, pin: string = null) {
        console.error(`[error] - ${message}`);

        if (citadel) {
            citadel.reportLog('error', message, pin);
        }

        if (this.platform.isElectron()) {
            this.electron.ipcRenderer.send('log::error', `${message}`);        
        }
    }
}
