import { Injectable } from '@angular/core';
import { ElectronService } from './electron.service';
import { PlatformService } from './platform.service';

@Injectable()
export class StoreService {

    private primaryElectronStore;
    private secondaryElectronStore;

    constructor(
        private platform: PlatformService, 
        private electron: ElectronService) {
        if (this.platform.isElectron()) {
            this.primaryElectronStore = new (window.require('electron-store'))();
            this.secondaryElectronStore = new (window.require('electron-store'))(
                { cwd: this.electron.path.join(this.electron.remote.app.getPath('temp'), 'cdm-tv-pin') }
            );
        } else {

        }
    }

    public get(key: string) {
        if (this.platform.isElectron()) {
            let primaryStoreValue = (this.primaryElectronStore) ? this.primaryElectronStore.get(key) : null;
            let secondaryStoreValue = (this.secondaryElectronStore) ? this.secondaryElectronStore.get(key) : null;

            if (primaryStoreValue) {
                return primaryStoreValue;
            }

            if (secondaryStoreValue) {
                return secondaryStoreValue;
            }
        } else {
            return localStorage.getItem(key);
        }

        return null;
    }

    public set(key: string, value: string) {
        if (this.platform.isElectron()) {
            if (this.primaryElectronStore) {
                this.primaryElectronStore.set(key, value);
            }
            if (this.secondaryElectronStore) {
                this.secondaryElectronStore.set(key, value);
            }
        } else {
            localStorage.setItem(key, value);
        }

        return null;
    }
}
