import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { CitadelService } from '../../../providers/citadel.service';
import { WifiService } from '../../../providers/wifi.service';
import { LoaderService } from '../../../providers/loader.service';
import { NotificationsService } from 'angular2-notifications';
import Keyboard from "simple-keyboard";
import KeyNavigation from "simple-keyboard-key-navigation";
import { LogService } from '../../../providers/log.service';
import { Buttons, Directions, RemoteService } from '../../../providers/remote.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-wifi',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './wifi.component.html',
  styleUrls: ['./wifi.component.scss']
})
export class WifiComponent implements OnInit, AfterViewInit {

  private keyboard: Keyboard;

  public MACAddresses: any[] = [];
  public networks: any[] = [];
  public showPassword: boolean = false;
  public currentlyConnectedNetwork: any = {};
  public currentlySelectedNetwork: any = null;
  public currentlyEnteredPassword: any = '';

  constructor(
    private loader: LoaderService,
    private location: Location,
    private log: LogService,
    private notifications: NotificationsService,
    private citadel: CitadelService,
    private wifi: WifiService,
    public remote: RemoteService
  ) { }

  async ngOnInit() {
    this.loader.setLoading(true);
    await this.refresh(false);
    await this.loadMACAddresses();

    this.remote.setSelectedMapping("wifi");
    this.loader.setLoading(false);
  }

  async ngAfterViewInit() {
    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      theme: 'hg-theme-default hg-layout-default darkTheme',
      newLineOnEnter: false,
      mergeDisplay: true,
      syncInstanceInputs: true,
      enableKeyNavigation: true,
      display: {
        '{enter}': 'Connect'
      },
      modules: [
        KeyNavigation
      ]
    });

    this.remote.onButtonPress("wifi", Buttons.BACK, () => {
      this.currentlySelectedNetwork = null;
      this.keyboard.setInput('');
    });

    this.remote.onButtonPress("wifi", Buttons.OK, () => {
      if (this.currentlySelectedNetwork) {
        this.keyboard['modules'].keyNavigation.press();
      }
    });

    this.remote.onDirectionPress("wifi", Directions.LEFT, () => {
      if (this.currentlySelectedNetwork) {
        this.keyboard['modules'].keyNavigation.left();
      }
    });

    this.remote.onDirectionPress("wifi", Directions.RIGHT, () => {
      if (this.currentlySelectedNetwork) {
        this.keyboard['modules'].keyNavigation.right();
      }
    });

    this.remote.onDirectionPress("wifi", Directions.UP, () => {
      if (this.currentlySelectedNetwork) {
        this.keyboard['modules'].keyNavigation.up();
      }
    });

    this.remote.onDirectionPress("wifi", Directions.DOWN, () => {
      if (this.currentlySelectedNetwork) {
        this.keyboard['modules'].keyNavigation.down();
      }
    });
  }

  public async back() {
    this.location.back();
  }

  public async connect(network: any) {
    this.log.info(JSON.stringify(network), this.citadel);

    if (network.security) {
      this.currentlySelectedNetwork = network;
    } else {
      this.attemptWifiConnection(network.ssid);
    }
  }

  public async attemptWifiConnection(ssid: string, password: string = undefined) {
    this.loader.setLoading(true, `Connecting to ${ssid}...`);

    this.currentlySelectedNetwork = null;
    this.currentlyEnteredPassword = '';
    this.keyboard.setInput('');

    try {
      await this.wifi.connect(ssid, password);
      await this.refresh(true);
      this.notifications.success('Success', 'Successfully connected to ' + ssid);
    } catch (e) {
      this.notifications.error('Network Error', e.message);
      this.log.error(`Could not connect to network - ${e.toString()}`, this.citadel);
    }

    this.loader.setLoading(false);
  }

  public async refresh(showLoading: boolean = true) {
    try {
      if (showLoading) this.loader.setLoading(true);
      let rawNetworks = (await this.wifi.scan()).filter(network => network.ssid);
      rawNetworks.sort((a, b) => b['signal'] - a['signal']);

      this.networks = [];
      for (let network of rawNetworks) {
        let alreadyHasSSID = !!this.networks.find(innerNetwork => innerNetwork.ssid === network.ssid);
        if (!alreadyHasSSID) {
          this.networks.push(network);
        }
      }


      if (showLoading) this.loader.setLoading(false);
    } catch (e) {
      this.log.error(`Could not refresh available networks - ${e.toString()}`, this.citadel);
      this.loader.setLoading(false);
    }

    this.remote.setMapping("wifi",
      [
        [{ action: () => this.refresh() }],
        ...this.networks.map(network => {
          return [{
            action: () => {
              if (this.currentlySelectedNetwork) {
                // ignore these operations if we're in the keyboard
                return;
              }
              if (network['in-use']) {
                return;
              } else {
                this.connect(network);
              }
            },
            value: network
          }]
        })
      ]
    );
  }

  public async loadMACAddresses() {
    try {
      let rawMACAddresses = await this.wifi.getMACAddresses();
      this.MACAddresses = (Object.keys(rawMACAddresses)).map(key => { return { interface: key, address: rawMACAddresses[key].mac } });
      this.MACAddresses = this.MACAddresses.filter(address => !address.interface.startsWith("tun"));
    } catch (e) {
      this.log.error(`Could not get MAC address information - ${e.toString()}`, this.citadel);
    }
  }

  public getPasswordString() {
    if (this.showPassword) {
      return this.currentlyEnteredPassword;
    } else {
      return new Array(this.currentlyEnteredPassword.length).fill('•').join('');
    }
  }

  // virtual keyboard handling

  public onChange(input: string) {
    this.currentlyEnteredPassword = input;
  }

  public async onKeyPress(button) {
    if (button === "{shift}" || button === "{lock}") {
      this.handleShift();
    } else if (button === "{enter}") {
      await this.attemptWifiConnection(this.currentlySelectedNetwork.ssid, this.currentlyEnteredPassword);
    }
  }

  public handleShift() {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle
    });
  };
}
