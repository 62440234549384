import { ApplicationRef, EventEmitter, Injectable } from '@angular/core';

export enum Directions {
    UP = 'UP',
    DOWN = 'DOWN',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
};

export enum Buttons {
    OK = "OK",
    MENU = "MENU",
    BACK = "BACK"
}

@Injectable()
export class RemoteService {

    private isRemoteEnabled: boolean = false;

    private mappings: any = {};
    private activeMapName: string;
    private emitter: EventEmitter<any> = new EventEmitter();

    constructor(private ref: ApplicationRef) {

    }

    public setMapping(name: string, actions: any[][]) {
        if (name === null) {
            return;
        }

        let maxRowCount = actions.length;
        let maxColCount = Math.max(...actions.map(row => row.length));

        let mapping = [];

        for (let r = 0; r < maxRowCount; r++) {
            let mappingRow = [];

            for (let c = 0; c < maxColCount; c++) {
                let entry = actions[r][c];

                if (!entry) {
                    mappingRow.push({
                        value: null,
                        action: null,
                        selected: false
                    });
                } else {
                    mappingRow.push({
                        value: entry.value,
                        action: entry.action,
                        selected: false
                    });
                }
            }

            mapping.push(mappingRow);
        }

        this.mappings[name] = {
            r: 0,
            c: 0,
            map: mapping
        };
    }

    public onButtonPress(name: string, button: Buttons, callback: Function) {
        // this.emitter.on(`${name}/${button}`, () => callback());
    }

    public onDirectionPress(name: string, direction: Directions, callback: Function) {
        // this.emitter.on(`${name}/${direction}`, () => callback());
    }

    public isSelected(name: string, r: number, c: number): boolean {
        if (!this.isRemoteEnabled) {
            return false;
        }

        if (!name) {
            return false;
        }

        let mapping = this.mappings[name];

        if (!mapping) {
            return false;
        }

        return mapping.r === r && mapping.c === c;
    }

    public isValueSelected(name: string, value: string): boolean {
        if (!this.isRemoteEnabled) {
            return false;
        }

        if (!name) {
            return false;
        }

        let mapping = this.mappings[name];

        if (!mapping) {
            return false;
        }

        let entry = mapping.map[mapping.r][mapping.c];
        return entry && entry.value === value;
    }

    public getCurrentCoordinates(name: string) {
        if (!this.isRemoteEnabled) {
            return;
        }

        if (!name) {
            return;
        }

        if (!this.mappings[name]) {
            return;
        }

        return { x: this.mappings[name].c, y: this.mappings[name].r };
    }

    public getCurrentAction(name: string) {
        if (!this.isRemoteEnabled) {
            return;
        }

        if (!name) {
            return;
        }

        if (!this.mappings[name]) {
            return;
        }

        let entry = this.mappings[name].map[this.mappings[name].r][this.mappings[name].c];
        return entry.action;
    }

    public pressButton(name: string, button: Buttons) {
        if (!this.isRemoteEnabled) {
            return;
        }

        if (!name) {
            return;
        }

        let mapping = this.mappings[name];

        if (!mapping) {
            return;
        }

        this.emitter.emit(`${name}/${button}`);

        switch (button) {
            case Buttons.OK:
                let entry = mapping.map[mapping.r][mapping.c];

                if (entry && entry.action) {
                    mapping.map[mapping.r][mapping.c].action();
                }
                break;
        }

        this.ref.tick();
    }

    public moveInDirection(name: string, direction: Directions) {
        if (!this.isRemoteEnabled) {
            return;
        }
        
        if (!name) {
            return;
        }

        let mapping = this.mappings[name];

        if (!mapping) {
            return;
        }

        this.emitter.emit(`${name}/${direction}`);

        switch (direction) {
            case Directions.UP:
                mapping.r = this.clampAndRotate(0, mapping.r - 1, mapping.map.length);
                break;
            case Directions.DOWN:
                mapping.r = this.clampAndRotate(0, mapping.r + 1, mapping.map.length);
                break;
            case Directions.LEFT:
                mapping.c = this.clampAndRotate(0, mapping.c - 1, mapping.map[mapping.r].length);
                break;
            case Directions.RIGHT:
                mapping.c = this.clampAndRotate(0, mapping.c + 1, mapping.map[mapping.r].length);
                break;
        }

        this.ref.tick();
    }

    public getSelectedMapping() {
        return this.activeMapName;
    }

    public setSelectedMapping(mapping: string) {
        this.activeMapName = mapping;
    }

    public setRemoteEnabled(active: boolean) {
        this.isRemoteEnabled = active;
    }

    public getRemoteEnabled() {
        return this.isRemoteEnabled;
    }

    private clampAndRotate(min: number, value: number, max: number) {
        if (value < min) {
            return max - 1;
        } else if (value >= max) {
            return min;
        } else {
            return value;
        }
    }
}
