import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { PlatformService } from './platform.service';
import { ElectronService } from './electron.service';

@Injectable()
export class VPNService {

    constructor(
        private log: LogService,
        private platform: PlatformService,
        private electron: ElectronService
    ) {
    }

    public getVPNIP(): string {
        try {
            const networkInterfaces = this.electron.os.networkInterfaces();
            for (const networkInterface of Object.keys(networkInterfaces)) {
                if (networkInterface === 'tun0') {
                    const ipv4Address = networkInterfaces[networkInterface].find(address => {
                        return address.family === 'IPv4'
                    });
                    
                    if (ipv4Address) {
                        return ipv4Address.address;
                    }
                }
            }
            return null;
        } catch (e) {
            return null;
        }
    }
}
