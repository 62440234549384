import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { PlatformService } from './platform.service';
import { ElectronService } from './electron.service';

@Injectable()
export class ActionService {

    private extensions = {
        'batch': 'bat',
        'bash': 'sh',
        'powershell': 'ps1'
    };

    private shellCommand = {
        'batch': ['C:\\Windows\\System32\\cmd.exe', '/c'],
        'bash': ['/bin/bash'],
        'powershell': ['C:\\Windows\\System32\\WindowsPowerShell\\v1.0\\powershell.exe', '-ExecutionPolicy unrestricted', '-command']
    };

    private fileHeaders = {
        'batch': '@echo off',
        'bash': '#!/bin/bash',
        'powershell': ''
    };

    constructor(
        private log: LogService,
        private platform: PlatformService,
        private electron: ElectronService
    ) {
    }

    public async executeAction(actionId: string, type: string, script: string) {
        const actionsDirectory = this.electron.path.join(this.electron.os.tmpdir(), 'cdm-actions');
        if (!this.electron.fs.existsSync(actionsDirectory)) {
            this.electron.fs.mkdirSync(actionsDirectory);
        }
    
        const tempFile = this.electron.path.join(actionsDirectory, `${actionId}.${this.extensions[type]}`);
        
        const fullScript = `${this.fileHeaders[type]}${this.electron.os.EOL}${script}`;
    
        this.electron.fs.writeFileSync(tempFile, fullScript);
    
        const command = [...this.shellCommand[type]];
        command.push(tempFile);

        const commandString = command.join(' ');
    
        return new Promise((resolve) => {
            this.electron.childProcess.exec(commandString, (err, stdout, stderr) => {
                if (err) {
                    resolve({ status: 'error', output: `${err}\n${stdout}\n${stderr}`})
                    return;
                }
                resolve({ status: 'success', output: stdout });
            });
        });
    }
}
