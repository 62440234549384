import { Injectable } from '@angular/core';

@Injectable()
export class PlatformService {

    private hasDetectedAndroid: boolean = false;

    constructor() {
        this.isAndroid();
    }

    public isBrowser(): boolean {
        return !this.isElectron();
    }

    public isAndroid(): boolean {
        // The URL query parameter can change, so let's store this immediately
        if (this.hasDetectedAndroid) {
            return true;
        }

        const urlParams = new URLSearchParams(window.location.search);

        const isAndroid = urlParams.get('platform') === 'android';

        if (isAndroid) {
            this.hasDetectedAndroid = true;
        }

        return isAndroid;
    }

    public isElectron() {
        return window && window.process && window.process.type;
    }

    public isWindows(): boolean {
        if (window.require) {
            return window.require('electron-platform').isWin32;
        } else {
            return false;
        }
    }

    public isLinux(): boolean {
        return !this.isBrowser() && !this.isWindows();
    }
}
