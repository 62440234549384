import { Injectable } from '@angular/core';

@Injectable()
export class LoaderService {
    private loading = false;
    private loaderText: string;

    private pageLoaded = false;
    // before page load
    private unloadedStatus = false;
    private unloadedText: string;

    constructor() { }

    public initializeOnPageload() {
        this.pageLoaded = true;
        this.loading = this.unloadedStatus;
        this.loaderText = this.unloadedText;
    }

    public setLoading(value: boolean, loaderText: string = null) {
        if (this.pageLoaded) {
            this.loading = value;
            this.loaderText = loaderText;
        } else {
            this.unloadedStatus = value;
            this.unloadedText = loaderText;
        }
    }

    public isLoading(): boolean {
        if (!this.pageLoaded) {
            return false;
        }
        return this.loading;
    }

    public getLoadingText(): string {
        return this.loaderText;
    }
}
