import { Injectable } from '@angular/core';

@Injectable()
export class ElectronService {

  ipcRenderer: any;
  webFrame: any;
  remote: any;
  childProcess: any;
  fs: any;
  os: any;
  app: any;
  path: any;

  constructor() {
    // Conditional imports
    if (this.isElectron()) {
      this.ipcRenderer = window.require('electron').ipcRenderer;
      this.webFrame = window.require('electron').webFrame;
      this.remote = window.require('@electron/remote');

      this.childProcess = window.require('child_process');
      this.fs = window.require('fs');
      this.os = window.require('os');
      this.path = window.require('path');

      // clear cache to flush out Electron memory leaks
      setInterval(() => {
        this.clearCache();
      }, 60 * 1000);
    }
  }

  private clearCache() {
    if (this.webFrame) {
      this.webFrame.clearCache();
    }
  }

  isElectron = () => {
    return window && window.process && window.process.type;
  }
}
