import { ErrorHandler, Injectable } from "@angular/core";
import { CitadelService } from "../providers/citadel.service";
import { LogService } from "../providers/log.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private logger: LogService, private citadel: CitadelService) { }
    handleError(error) {
        try {
            this.logger.error(error.toString(), this.citadel);
        } catch (e) {
            // just swallow the error
        }
    }
}